// src/DailyTaskTracker.js
import React, { useState, useEffect } from 'react';

function DailyTaskTracker({ onComplete }) {
  const initialTasks = [
    { id: 1, name: 'Workout', completed: false },
    { id: 2, name: 'Read 10 pages', completed: false },
    { id: 3, name: 'Drink 1 gallon of water', completed: false },
    { id: 4, name: 'Follow diet', completed: false },
    { id: 5, name: 'Take a progress photo', completed: false },
  ];

  const [tasks, setTasks] = useState(() => {
    const savedTasks = JSON.parse(localStorage.getItem('currentTasks'));
    return savedTasks ? savedTasks : initialTasks;
  });

  useEffect(() => {
    // Save tasks to local storage
    localStorage.setItem('currentTasks', JSON.stringify(tasks));
    
    // Check if all tasks are completed
    const allTasksCompleted = tasks.every(task => task.completed);
    onComplete(allTasksCompleted);
  }, [tasks, onComplete]);

  const toggleTaskCompletion = (taskId) => {
    setTasks(tasks.map(task =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    ));
  };

  return (
    <div>
      <h2>Today's Tasks</h2>
      <ul>
        {tasks.map(task => (
          <li key={task.id} onClick={() => toggleTaskCompletion(task.id)} className="task-item">
            <input
              type="checkbox"
              checked={task.completed}
              readOnly
            />
            <span style={{ textDecoration: task.completed ? 'line-through' : 'none' }}>
              {task.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DailyTaskTracker;
