// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import DailyTaskTracker from './DailyTaskTracker';
import Dashboard from './components/Dashboard';
import Streaks from './components/Streaks';
import PhotoUpload from './components/PhotoUpload';
import CalendarView from './components/CalendarView';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container mx-auto mt-8">
        <ul className="flex space-x-4">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/dashboard">Dashboard</Link></li>
          <li><Link to="/streaks">Streaks</Link></li>
          <li><Link to="/photo-upload">Photo Upload</Link></li>
          <li><Link to="/calendar">Calendar</Link></li>
        </ul>
        <Routes>
          <Route path="/" element={<DailyTaskTracker />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/streaks" element={<Streaks />} />
          <Route path="/photo-upload" element={<PhotoUpload />} />
          <Route path="/calendar" element={<CalendarView />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
