// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom'; // Use React Router for navigation

function Navbar() {
  return (
    <nav className="bg-blue-600 p-4 text-white">
      <div className="container mx-auto flex justify-between">
        <div className="text-xl font-bold">75 Hard Tracker</div>
        <div className="space-x-4">
          <Link to="/" className="hover:underline">Home</Link>
          <Link to="/dashboard" className="hover:underline">Dashboard</Link>
          <Link to="/settings" className="hover:underline">Settings</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
