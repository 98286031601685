// src/components/CalendarView.js
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function CalendarView() {
  const [taskHistory, setTaskHistory] = useState(() => JSON.parse(localStorage.getItem('taskHistory')) || {});

  const tileClassName = ({ date }) => {
    const dayString = `Day ${date.getDate()}`;
    if (taskHistory[dayString]) {
      return 'bg-green-200'; // Green for completed days
    } else if (new Date() > date) {
      return 'bg-red-200'; // Red for missed days
    } else {
      return '';
    }
  };

  return (
    <div>
      <Calendar
        tileClassName={tileClassName}
      />
    </div>
  );
}

export default CalendarView;
