// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';

function Dashboard() {
  const [stats, setStats] = useState({
    completedDays: 0,
    totalWorkouts: 0,
    totalPagesRead: 0,
    totalWaterIntake: 0
  });

  useEffect(() => {
    const savedStats = JSON.parse(localStorage.getItem('stats')) || stats;
    setStats(savedStats);
  }, []);

  useEffect(() => {
    localStorage.setItem('stats', JSON.stringify(stats));
  }, [stats]);

  const handleTaskCompletion = () => {
    setStats({
      ...stats,
      completedDays: stats.completedDays + 1,
      totalWorkouts: stats.totalWorkouts + 2,
      totalPagesRead: stats.totalPagesRead + 1,
      totalWaterIntake: stats.totalWaterIntake + 1
    });
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <p>Completed Days: {stats.completedDays}</p>
        <p>Total Workouts: {stats.totalWorkouts}</p>
        <p>Pages Read: {stats.totalPagesRead}</p>
        <p>Total Water Intake (gallons): {stats.totalWaterIntake}</p>
      </div>
      <button 
        onClick={handleTaskCompletion} 
        className="bg-blue-500 text-white p-2 rounded mt-4">
        Debug: Complete All Tasks for the Day
      </button>
    </div>
  );
}

export default Dashboard;
