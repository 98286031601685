// src/components/Streaks.js
import React, { useState, useEffect } from 'react';

function Streaks() {
  const [streak, setStreak] = useState(0);

  useEffect(() => {
    const savedStreak = parseInt(localStorage.getItem('currentStreak'), 10) || 0;
    setStreak(savedStreak);
  }, []);

  const incrementStreak = () => {
    const newStreak = streak + 1;
    setStreak(newStreak);
    localStorage.setItem('currentStreak', newStreak);
  };

  const resetStreak = () => {
    setStreak(0);
    localStorage.setItem('currentStreak', 0);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Current Streak</h2>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <p>Consecutive Days Completed: {streak}</p>
      </div>
      <div className="flex space-x-4 mt-4">
        <button onClick={incrementStreak} className="bg-green-500 text-white p-2 rounded">Debug: Increment Streak</button>
        <button onClick={resetStreak} className="bg-red-500 text-white p-2 rounded">Debug: Reset Streak</button>
      </div>
    </div>
  );
}

export default Streaks;
