// src/components/PhotoUpload.js
import React, { useState } from 'react';

function PhotoUpload() {
  const [gallery, setGallery] = useState(() => JSON.parse(localStorage.getItem('photoGallery')) || []);
  const [currentDay, setCurrentDay] = useState(() => {
    return parseInt(localStorage.getItem('currentDay'), 10) || 1;
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newGallery = [...gallery];
        const dayIndex = newGallery.findIndex(entry => entry.day === `Day ${currentDay}`);
        
        if (dayIndex !== -1) {
          newGallery[dayIndex].images.push(reader.result);
        } else {
          newGallery.push({ day: `Day ${currentDay}`, images: [reader.result] });
        }

        setGallery(newGallery);
        localStorage.setItem('photoGallery', JSON.stringify(newGallery));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <h2>Upload Daily Progress Photo</h2>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <div className="gallery mt-4">
        <h3>Gallery</h3>
        {gallery.map((entry, index) => (
          <div key={index}>
            <h4>{entry.day}</h4>
            <div className="flex space-x-2">
              {entry.images.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  src={image}
                  alt={`Progress for ${entry.day}`}
                  className="w-48 h-48 object-cover"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoUpload;
